import { BearerToken } from "@/domain/bearerTokens/bearerToken";
import {
  AbstractForecastJobsRepository
} from "@/infra/legacyOptimizer/forecastJobsRepository/abstractForecastJobsRepository";
import { ForecastJob } from "@/domain/legacyOptimizer/forecastJob/forecastJob";
import { ThanosAPI } from "@/infra/api/thanos_api/thanos_api";
import { ThanosAPIForecastJob } from "@/infra/api/thanos_api/models";

export class APIForecastJobsRepository implements AbstractForecastJobsRepository {
  private readonly api: ThanosAPI;

  constructor(api: ThanosAPI) {
    this.api = api;
  }

  createForecastJob(
    bearerToken: BearerToken,
    customerId: string,
    modelSetId: string,
    forecastJobName: string
  ): Promise<ThanosAPIForecastJob> {
    return this.api.forecastJobsPost(
      bearerToken,
      customerId,
      modelSetId,
      forecastJobName
    ).then()
      .catch(e => {
        throw Error(e);
      });
  }

  getForecastJobsByCustomerIdAndModelSetId(
    bearerToken: BearerToken,
    customerId: string,
    modelSetId: string
  ): Promise<ForecastJob[]> {
    return this.api
      .forecastJobsGet(bearerToken, customerId, modelSetId)
      .then((apiForecastJobs) =>
        apiForecastJobs.map(
          (apiForecastJob) =>
            new ForecastJob({
              name: apiForecastJob.attributes.job_name,
              type: apiForecastJob.attributes.job_type,
              builderStatus: apiForecastJob.attributes.status,
              detail: apiForecastJob.attributes.detail,
              remainingSubJobs: apiForecastJob.attributes.remaining_subjobs,
              createdTimestamp: apiForecastJob.attributes.created,
              builderJobID: apiForecastJob.attributes.builder_job_id,
              optimizerJobID: apiForecastJob.attributes.optimizer_job_id,
              finalizerJobID: apiForecastJob.attributes.finalizer_job_id,
              status: apiForecastJob.attributes.status
            })
        )
      )
      .catch(e => {
          throw Error(e);
        }
      );
  }

  submitForecastJob(
    bearerToken: BearerToken,
    customerId: string,
    modelSetId: string,
    forecastJobName: string
  ): Promise<void> {
    return this.api.forecastsJobsPatch(
      bearerToken,
      customerId,
      modelSetId,
      forecastJobName
    )
      .then()
      .catch(e => {
        console.error(e);
      });
  }

  downloadForecastJobResult(
    bearerToken: BearerToken,
    customerId: string,
    modelSetId: string,
    forecastJobName: string
  ): Promise<void> {
    return this.api
      .forecastDownloadLinkPost(
        bearerToken,
        customerId,
        modelSetId,
        forecastJobName
      )
      .then((downloadLink: string) =>
        this.api
          .downloadLinkGet(downloadLink)
          .then()
          .catch((e) => {
            console.error(e);
          })
      )
      .catch((e) => {
        console.error(e);
      });
  }

  deleteForecastJob(
    bearerToken: BearerToken,
    customerId: string,
    modelSetId: string,
    forecastJobName: string
  ): Promise<void> {
    return this.api.forecastJobsDelete(
      bearerToken,
      customerId,
      modelSetId,
      forecastJobName
    )
      .then()
      .catch(e => {
          throw Error(e);
        }
      );
  }
}

<template>
  <v-sheet elevation="2" border="black" width="1700px" class="d-flex flex-column align-center">
    <h2 class="pt-6 pb-2 text-h3">Dashboard</h2>
    <p class="py-3 text-body-1">Choose one of the following options</p>
    <div class="d-flex justify-center flex-column pb-6 pt-2">
      <v-btn
          class="mx-4 my-3"
          variant="outlined"
          @click="$router.push({ path: '/processing-jobs' }).catch(() => {})">
        Processing Jobs
      </v-btn>
      <v-btn
          class="mx-4 my-3"
          variant="outlined"
          @click="$router.push({ path: '/legacy-optimizer' }).catch(() => {})">
        Legacy Optimizer
      </v-btn>
    </div>
  </v-sheet>
</template>

interface JobMetadataParams {
    id: string;
    type: string;
    status: string;
    createdTimestamp: Date;
    lastUpdatedTimestamp: Date;
}

/**
 * Metadata for jobs such as for ForecastJob, OptimizerJob, FinalizerJob primarily used for the JobsSummary
 */
export class JobMetadata {
    id: string;
    type: string;
    status: string;
    createdTimestamp: Date;
    lastUpdatedTimestamp: Date;

    constructor(params: JobMetadataParams) {
        this.id = params.id;
        this.type = params.type;
        this.status = params.status;
        this.createdTimestamp = params.createdTimestamp;
        this.lastUpdatedTimestamp = params.lastUpdatedTimestamp;
    }
}

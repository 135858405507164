export interface ScenarioRuleParams {
  name: string;
  itemDeathRuleName: string;
  annualVariableHoldingCost: number;
  receiptLineCostFixed: number;
  backorderLineCostFixed: number;
  backorderCostVariable: number;
  spillLineCostFixed: number;
  spillLineCostVariable: number;
  stockoutSpillRate: number;
}

export class ScenarioRule {
  name: string;
  itemDeathRuleName: string;
  annualVariableHoldingCost: number;
  receiptLineCostFixed: number;
  backorderLineCostFixed: number;
  backorderCostVariable: number;
  spillLineCostFixed: number;
  spillLineCostVariable: number;
  stockoutSpillRate: number;

  constructor(params: ScenarioRuleParams) {
    this.name = params.name;
    this.itemDeathRuleName = params.itemDeathRuleName;
    this.annualVariableHoldingCost = params.annualVariableHoldingCost;
    this.receiptLineCostFixed = params.receiptLineCostFixed;
    this.backorderLineCostFixed = params.backorderLineCostFixed;
    this.backorderCostVariable = params.backorderCostVariable;
    this.spillLineCostFixed = params.spillLineCostFixed;
    this.spillLineCostVariable = params.spillLineCostVariable;
    this.stockoutSpillRate = params.stockoutSpillRate;
  }
}

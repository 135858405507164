import {HeimdallAPI} from "@/infra/api/heimdall/heimdall";
import {Customer} from "@/domain/customers/customer";
import {BearerToken} from "@/domain/bearerTokens/bearerToken";
import {AbstractCustomersRepository} from "@/infra/customers/abstractCustomersRepository";
import {APIErrorType, RepositoryError, RepositoryErrorType} from "@/infra/common/errors";

export class APICustomersRepository implements AbstractCustomersRepository {
    private readonly api: HeimdallAPI;

    constructor(api: HeimdallAPI) {
        this.api = api;
    }

    async getCustomers(bearerToken: BearerToken): Promise<Array<Customer>> {
        return this.api.customersGet(bearerToken)
            .then(results => {
                const customers = [];
                for (const customer of results) {
                    if (customer.active_in_legacy_optimizer) {
                        const uuid = customer.uuid;
                        const name = customer.name;
                        const displayName = customer.display_name;
                        const customerId = customer.client_id;
                        customers.push(new Customer({uuid, name, displayName, customerId}));
                    }
                }
                return customers;
            })
            .catch(e => {
                if (e.errorType == APIErrorType.InvalidAuthToken) {
                    throw new RepositoryError("getCustomers", RepositoryErrorType.InvalidAuthToken, e);
                } else {
                    throw new RepositoryError("getCustomers", RepositoryErrorType.Unknown, e);
                }
            });
    }
}

import {S3File} from "@/domain/legacyOptimizer/s3File/s3File";

interface ModelSetParams {
    name: string;
    status: string;
    detail: string;
    createdDatetime: string;
    buckets?: S3File;
    delayDays?: S3File;
    exceptions?: S3File;
    marginFloor?: S3File;
    products?: S3File;
    receiving?: S3File;
    sales?: S3File;
}

export class ModelSet {
    name: string;
    status: string;
    detail: string;
    createdDatetime: string;
    buckets?: S3File;
    delayDays?: S3File;
    exceptions?: S3File;
    marginFloor?: S3File;
    products?: S3File;
    receiving?: S3File;
    sales?: S3File;

    constructor(params: ModelSetParams) {
        this.name = params.name;
        this.status = params.status;
        this.detail = params.detail;
        this.createdDatetime = params.createdDatetime;
        this.buckets = params.buckets;
        this.delayDays = params.delayDays;
        this.exceptions = params.exceptions;
        this.marginFloor = params.marginFloor;
        this.products = params.products;
        this.receiving = params.receiving;
        this.sales = params.sales;
    }
}

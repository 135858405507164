import {UUID} from "@/domain/common/types";

interface FileMetadataParams {
    fileUUID: UUID;
    fileName: string;
    uploadedDatetime: Date;
}

export class FileMetadata {
    fileUUID: UUID;
    fileName: string;
    uploadedDatetime: Date;

    constructor(
        params: FileMetadataParams
    ) {
        this.fileUUID = params.fileUUID;
        this.fileName = params.fileName;
        this.uploadedDatetime = params.uploadedDatetime;
    }
}

import {BearerToken} from "@/domain/bearerTokens/bearerToken";
import {
    AbstractJobsSummariesRepository
} from "@/infra/legacyOptimizer/jobsSummariesRepository/abstractJobsSummariesRepository";
import {HeimdallAPI} from "@/infra/api/heimdall/heimdall";
import {JobsSummary} from "@/domain/legacyOptimizer/jobsSummary/jobsSummary";
import {APIErrorType, InfraErrorMessages, RepositoryError, RepositoryErrorType} from "@/infra/common/errors";
import {Customer} from "@/domain/customers/customer";
import {JobMetadata} from "@/domain/legacyOptimizer/jobMetadata/jobMetadata";


export class ApiJobsSummariesRepository implements AbstractJobsSummariesRepository {
    private readonly api: HeimdallAPI;

    constructor(api: HeimdallAPI) {
        this.api = api;
    }

    private convertApiJobToJobMetadata(apiJob: any): JobMetadata {
        return new JobMetadata({
            id: apiJob.id,
            type: apiJob.type,
            status: apiJob.status,
            createdTimestamp: new Date(apiJob.created_datetime),
            lastUpdatedTimestamp: new Date(apiJob.updated_datetime),
        });
    }

    private convertApiCustomerToCustomer(apiCustomer: any): Customer {
        return new Customer({
            uuid: apiCustomer.uuid,
            name: apiCustomer.name,
            displayName: apiCustomer.displayName,
            customerId: apiCustomer.customerId,
        });
    }

    getJobsSummaries(
        bearerToken: BearerToken,
    ): Promise<JobsSummary[]> {
        return this.api
            .jobsSummariesGet(bearerToken)
            .then((apiJobSummaries) =>
                apiJobSummaries.map(
                    (apiJobsSummary: any) =>
                        new JobsSummary({
                            customer: this.convertApiCustomerToCustomer(apiJobsSummary.customer),
                            activeJobsMetadata: apiJobsSummary.active_jobs.map(this.convertApiJobToJobMetadata),
                            successfulJobsMetadata: apiJobsSummary.successful_jobs.map(this.convertApiJobToJobMetadata),
                            failedJobsMetadata: apiJobsSummary.failed_jobs.map(this.convertApiJobToJobMetadata),
                        })
                ))
            .catch((e) => {
                if (e.errorType == APIErrorType.InvalidAuthToken) {
                    throw new RepositoryError("getJobsSummaries", RepositoryErrorType.InvalidAuthToken,
                        InfraErrorMessages.InvalidAuthToken);
                } else {
                    throw new RepositoryError("getJobsSummaries", RepositoryErrorType.Unknown, e);
                }
            });

    }
}

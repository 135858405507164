import {BearerToken} from "@/domain/bearerTokens/bearerToken";
import {AbstractModelSetsRepository} from "@/infra/legacyOptimizer/modelSetsRepository/abstractModelSetsRepository";
import {ModelSet} from "@/domain/legacyOptimizer/modelSet/modelSet";
import {ThanosAPI} from "@/infra/api/thanos_api/thanos_api";
import {
    convertS3FileToThanosAPIFile,
    convertThanosAPIFileToS3File,
    S3File
} from "@/domain/legacyOptimizer/s3File/s3File";
import {ThanosAPIModelSetFiles} from "@/infra/api/thanos_api/models";

export class APIModelSetsRepository implements AbstractModelSetsRepository {
    private readonly api: ThanosAPI;

    constructor(api: ThanosAPI) {
        this.api = api;
    }

    async createModelSet(
        bearerToken: BearerToken,
        customerId: string,
        modelSetId: string
    ): Promise<void> {
        await this.api.modelSetsPost(bearerToken, customerId, modelSetId);
    }

    async updateModelSet(
        bearerToken: BearerToken,
        customerId: string,
        modelSetId: string,
        buckets?: S3File,
        delayDays?: S3File,
        exceptions?: S3File,
        marginFloor?: S3File,
        products?: S3File,
        receiving?: S3File,
        sales?: S3File
    ): Promise<void> {
        const thanosAPIModelSetFiles: ThanosAPIModelSetFiles = {
            buckets: buckets ? convertS3FileToThanosAPIFile(buckets) : undefined,
            delay_days: delayDays ? convertS3FileToThanosAPIFile(delayDays) : undefined,
            exceptions: exceptions ? convertS3FileToThanosAPIFile(exceptions) : undefined,
            margin_floor: marginFloor ? convertS3FileToThanosAPIFile(marginFloor) : undefined,
            products: products ? convertS3FileToThanosAPIFile(products) : undefined,
            receiving: receiving ? convertS3FileToThanosAPIFile(receiving) : undefined,
            sales: sales ? convertS3FileToThanosAPIFile(sales) : undefined
        };
        await this.api.modelSetsPost(
            bearerToken,
            customerId,
            modelSetId,
            thanosAPIModelSetFiles
        );
    }

    getModelSetsByCustomerId(
        bearerToken: BearerToken,
        customerId: string
    ): Promise<ModelSet[]> {
        return this.api
            .modelSetsGet(bearerToken, customerId)
            .then((apiModelSets) => {
                const modelSets: ModelSet[] = [];
                for (const apiModelSet of apiModelSets) {
                    modelSets.push(
                        new ModelSet({
                            name: apiModelSet.attributes.model_set_name,
                            status: apiModelSet.attributes.status,
                            detail: apiModelSet.attributes.detail,
                            createdDatetime: apiModelSet.attributes.created,
                            buckets: convertThanosAPIFileToS3File(apiModelSet.attributes.buckets),
                            delayDays: convertThanosAPIFileToS3File(apiModelSet.attributes.delay_days),
                            marginFloor: convertThanosAPIFileToS3File(apiModelSet.attributes.margin_floor),
                            exceptions: convertThanosAPIFileToS3File(apiModelSet.attributes.exceptions),
                            products: convertThanosAPIFileToS3File(apiModelSet.attributes.products),
                            receiving: convertThanosAPIFileToS3File(apiModelSet.attributes.receiving),
                            sales: convertThanosAPIFileToS3File(apiModelSet.attributes.sales),
                        })
                    );
                }
                return modelSets;
            })
            .catch(e => {
                    console.error(e);
                    throw Error(e);
                }
            );
    }

    async submitModelSetJob(
        bearerToken: BearerToken,
        customerId: string,
        modelSetId: string
    ): Promise<void> {
        await this.api.modelSetsPatch(bearerToken, customerId, modelSetId);
    }

    async deleteModelSet(
        bearerToken: BearerToken,
        customerId: string,
        modelSetId: string
    ): Promise<void> {
        await this.api.modelSetsDelete(bearerToken, customerId, modelSetId);
    }
}

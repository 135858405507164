import Vuex from "vuex";
import { BearerToken } from "@/domain/bearerTokens/bearerToken";
import { APIBearerTokensRepository } from "@/infra/bearerTokens/apiBearerTokensRepository";
import createPersistedState from "vuex-persistedstate";
import { getConfig } from "@/common/config";
import { HeimdallAPI } from "@/infra/api/heimdall/heimdall";
import { RepositoryErrorType } from "@/infra/common/errors";
import { ServiceError, ServiceErrorMessages, ServiceErrorType } from "@/service/common/errors";

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ],
  state: {
    bearerToken: null
  },
  getters: {
    isLoggedIn: (state: any) => !!state.bearerToken,
    bearerToken: (state: any) => state.bearerToken
  },
  actions: {
    async login(context: any, payload: any) {
      const config = getConfig();
      const api = new HeimdallAPI(config.heimdallEndpoint);
      const bearerTokensRepository = new APIBearerTokensRepository(api);
      await bearerTokensRepository
        .obtainBearerToken(payload.username, payload.password)
        .then((token) => {
          context.commit("setBearerToken", token);
        })
        .catch((e) => {
          if (e.errorType === RepositoryErrorType.NetworkError) {
            throw new ServiceError("login", ServiceErrorType.NetworkError, ServiceErrorMessages.NetworkError);
          } else if (e.errorType === RepositoryErrorType.InvalidUsernameOrPassword) {
            throw new ServiceError("login", ServiceErrorType.InvalidUsernameOrPassword, ServiceErrorMessages.InvalidUsernamePassword);
          } else {
            throw new ServiceError("login", ServiceErrorType.Unknown, ServiceErrorMessages.Unknown);
          }
        });

    },
    logout(context: any) {
      context.commit("setBearerToken", null);
    }
  },
  mutations: {
    setBearerToken(state: any, bearerToken: BearerToken) {
      state.bearerToken = bearerToken;
    }
  }
});

export default store;

import {UUID} from "@/domain/common/types";

interface CustomerParams {
    uuid: UUID;
    name: string;
    displayName: string;
    customerId: string;
}

export class Customer {
    uuid: UUID;
    name: string;
    displayName: string;
    customerId: string;

    constructor(
        params: CustomerParams
    ) {
        this.uuid = params.uuid;
        this.name = params.name;
        this.displayName = params.displayName;
        this.customerId = params.customerId;
    }
}

import {BearerToken} from "@/domain/bearerTokens/bearerToken";
import {HeimdallAPI} from "@/infra/api/heimdall/heimdall";
import {APIErrorType, InfraErrorMessages, RepositoryError, RepositoryErrorType} from "@/infra/common/errors";
import {UUID} from "@/domain/common/types";
import {AbstractFileUploadRepository} from "@/infra/files/filesRepository/abstractFilesRepository";

export class APICustomerConfigurationFilesRepository implements AbstractFileUploadRepository {
    private readonly api: HeimdallAPI;

    constructor(api: HeimdallAPI) {
        this.api = api;
    }

    /**
     * This method is used to upload a customer configuration file.
     *
     * @param bearerToken - The bearer token to use for authentication.
     * @param file - The file to upload.
     * @param fileIdentifier - The customerUUID for the file to be associated with. (named fileIdentifier
     * to comply with AbstractFilesRepository API
     */
    async uploadFile(bearerToken: BearerToken, file: File, fileIdentifier: string): Promise<string> {
        return this.api.customerConfigurationFilePost(bearerToken, file, fileIdentifier)
            .catch((e) => {
                if (e.errorType == APIErrorType.InvalidAuthToken) {
                    throw new RepositoryError("uploadFile", RepositoryErrorType.InvalidAuthToken, InfraErrorMessages.InvalidAuthToken);
                } else {
                    throw new RepositoryError("uploadFile", RepositoryErrorType.Unknown, e);
                }
            });
    }

    /**
     * This method is used to download a customer configuration file.
     *
     * @param bearerToken - The bearer token to use for authentication.
     * @param customerUUID - The UUID of the customer to download the file for.
     */
    async downloadCustomerConfigFile(
        bearerToken: BearerToken,
        customerUUID: UUID,
    ): Promise<void> {
        return this.api.customerConfigurationFileGet(bearerToken, customerUUID)
            .catch((e) => {
                if (e.errorType == APIErrorType.InvalidAuthToken) {
                    throw new RepositoryError("downloadCustomerConfigFile", RepositoryErrorType.InvalidAuthToken,
                        InfraErrorMessages.InvalidAuthToken);
                } else {
                    throw new RepositoryError("downloadCustomerConfigFile", RepositoryErrorType.Unknown, e);
                }
            });
    }
}

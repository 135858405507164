import { ScenarioRule } from "@/domain/legacyOptimizer/scenarioRule/scenarioRule";
import { ItemDeathRule } from "@/domain/legacyOptimizer/itemDeathRules/itemDeathRules";

export interface CustomerConfigParams {
  monthlyDecayLeadTime: number;
  monthlyDecaySalePrice: number;
  monthlyDecaySalesOrders: number;
  monthlyDecaySalesQty: number;
  simDaysPerTrial: number;
  simMaxTrials: number;
  simMinTrials: number;
  simNeighborhood: number;
  simTrialsThresholdHigh: number;
  simTrialsThresholdLow: number;
  itemDeathRules: ItemDeathRule[];
  scenarioRules: ScenarioRule[];
}

export class CustomerConfig {
  monthlyDecayLeadTime: number;
  monthlyDecaySalePrice: number;
  monthlyDecaySalesOrders: number;
  monthlyDecaySalesQty: number;
  simDaysPerTrial: number;
  simMaxTrials: number;
  simMinTrials: number;
  simNeighborhood: number;
  simTrialsThresholdHigh: number;
  simTrialsThresholdLow: number;
  itemDeathRules: ItemDeathRule[];
  scenarioRules: ScenarioRule[];

  constructor(params: CustomerConfigParams) {
    this.monthlyDecayLeadTime = params.monthlyDecayLeadTime;
    this.monthlyDecaySalePrice = params.monthlyDecaySalePrice;
    this.monthlyDecaySalesOrders = params.monthlyDecaySalesOrders;
    this.monthlyDecaySalesQty = params.monthlyDecaySalesQty;
    this.simDaysPerTrial = params.simDaysPerTrial;
    this.simMaxTrials = params.simMaxTrials;
    this.simMinTrials = params.simMinTrials;
    this.simNeighborhood = params.simNeighborhood;
    this.simTrialsThresholdHigh = params.simTrialsThresholdHigh;
    this.simTrialsThresholdLow = params.simTrialsThresholdLow;
    this.itemDeathRules = params.itemDeathRules;
    this.scenarioRules = params.scenarioRules;
  }
}

import {BearerToken} from "@/domain/bearerTokens/bearerToken";
import {
    AbstractOptimizationJobsRepository
} from "@/infra/legacyOptimizer/optimizationJobsRepository/abstractOptimizationJobsRepository";
import {OptimizationJob, OptimizationJobDetails} from "@/domain/legacyOptimizer/optimizationJob/optimizationJob";
import {ThanosAPI} from "@/infra/api/thanos_api/thanos_api";
import {
    convertS3FileToThanosAPIFile,
    convertThanosAPIFileToS3File,
    S3File,
} from "@/domain/legacyOptimizer/s3File/s3File";
import {ThanosAPIOptimizationJobFiles} from "@/infra/api/thanos_api/models";
import {HeimdallAPI} from "@/infra/api/heimdall/heimdall";
import {RepositoryError, RepositoryErrorType} from "@/infra/common/errors";

export class APIOptimizationJobsRepository implements AbstractOptimizationJobsRepository {
    private readonly thanosAPI: ThanosAPI;
    private readonly heimdallAPI: HeimdallAPI;

    /* This is a bit of an odd pattern having two APIs for one repository. But we are
     * transitioning from Thanos over to Heimdall and this will make that transition smoother
     * (as opposed to creating two repositories with one API per each). The expectation is that this
     * is temporary and eventually the Thanos API will be removed.
     */
    constructor(thanosAPI: ThanosAPI, heimdallAPI: HeimdallAPI) {
        this.thanosAPI = thanosAPI;
        this.heimdallAPI = heimdallAPI;
    }

    async createOptimizationJob(
        bearerToken: BearerToken,
        customerId: string,
        modelSetId: string,
        optimizationJobName: string
    ): Promise<void> {
        await this.thanosAPI.optimizationJobsPost(
            bearerToken,
            customerId,
            modelSetId,
            optimizationJobName
        );
    }

    async updateOptimizationJob(
        bearerToken: BearerToken,
        customerId: string,
        modelSetId: string,
        optimizationJobName: string,
        items?: S3File,
        forecast?: S3File
    ): Promise<void> {
        const thanosAPIOptimizationJobFiles: ThanosAPIOptimizationJobFiles = {
            items: items ? convertS3FileToThanosAPIFile(items) : undefined,
            forecast: forecast ? convertS3FileToThanosAPIFile(forecast) : undefined,
        };

        await this.thanosAPI.optimizationJobsPost(
            bearerToken,
            customerId,
            modelSetId,
            optimizationJobName,
            thanosAPIOptimizationJobFiles
        );
    }

    async getOptimizationJobsByCustomerIdAndModelSetId(
        bearerToken: BearerToken,
        customerId: string,
        modelSetId: string
    ): Promise<OptimizationJob[]> {
        // Get Optimization Jobs for each retrieved Model Set ID
        return await this.thanosAPI
            .optimizationJobsGet(bearerToken, customerId, modelSetId)
            .then((apiOptimizationJobs) =>
                apiOptimizationJobs.map(
                    (apiOptimizationJob) =>
                        new OptimizationJob({
                            name: apiOptimizationJob.attributes.job_name,
                            builderStatus: "TODO: Figure this one out",
                            detail: apiOptimizationJob.attributes.detail,
                            remainingSubJobs: apiOptimizationJob.attributes.remaining_subjobs,
                            createdTimestamp: apiOptimizationJob.attributes.created,
                            builderJobID: apiOptimizationJob.attributes.builder_job_id,
                            optimizerJobID: apiOptimizationJob.attributes.optimizer_job_id,
                            finalizerJobID: apiOptimizationJob.attributes.finalizer_job_id,
                            status: apiOptimizationJob.attributes.status,
                            itemsFile: convertThanosAPIFileToS3File(
                                apiOptimizationJob.attributes.items
                            ),
                            forecastFile: convertThanosAPIFileToS3File(
                                apiOptimizationJob.attributes.forecast
                            ),
                        })
                )
            );
    }

    convertApiOptimizationJobDetailsListToOptimizationJobDetailsList(apiOptimizationJobDetailsList: any): OptimizationJobDetails[] {
        return apiOptimizationJobDetailsList.map((apiOptimizationJobDetails: any) => {
            return new OptimizationJobDetails({
                jobName: apiOptimizationJobDetails.job_name,
                status: apiOptimizationJobDetails.status,
                totalJobItems: apiOptimizationJobDetails.total_job_items,
                optimizerBuildJobId: apiOptimizationJobDetails.optimizer_build_job?.id,
                optimizerBuildJobStatus: apiOptimizationJobDetails.optimizer_build_job?.status,
                optimizerOptimizeJobId: apiOptimizationJobDetails.optimizer_optimize_job?.id,
                optimizerOptimizeJobStatus: apiOptimizationJobDetails.optimizer_optimize_job?.status,
                optimizerOptimizeJobNumberOfSubJobsStarting: apiOptimizationJobDetails?.optimizer_optimize_job?.number_of_sub_jobs_starting,
                optimizerOptimizeJobNumberOfSubJobsFailed: apiOptimizationJobDetails?.optimizer_optimize_job?.number_of_sub_jobs_failed,
                optimizerOptimizeJobNumberOfSubJobsRunning: apiOptimizationJobDetails?.optimizer_optimize_job?.number_of_sub_jobs_running,
                optimizerOptimizeJobNumberOfSubJobsSucceeded: apiOptimizationJobDetails?.optimizer_optimize_job?.number_of_sub_jobs_succeeded,
                optimizerOptimizeJobNumberOfSubJobsRunnable: apiOptimizationJobDetails?.optimizer_optimize_job?.number_of_sub_jobs_runnable,
                optimizerOptimizeJobNumberOfSubJobsSubmitted: apiOptimizationJobDetails?.optimizer_optimize_job?.number_of_sub_jobs_submitted,
                optimizerOptimizeJobNumberOfSubJobsPending: apiOptimizationJobDetails?.optimizer_optimize_job?.number_of_sub_jobs_pending,
                optimizerFinalizeJobId: apiOptimizationJobDetails?.optimizer_finalize_job?.id,
                optimizerFinalizeJobStatus: apiOptimizationJobDetails?.optimizer_finalize_job?.status,
            });
        });
    }

    async getOptimizationJobDetailsByCustomerIdModelSetIdAndJobName(
        bearerToken: BearerToken,
        customerId: string,
        modelSetId: string,
        jobName: string
    ): Promise<OptimizationJobDetails[]> {
        return await this.heimdallAPI
            .optimizerJobDetailsGet(bearerToken, customerId, modelSetId, jobName)
            .then((apiOptimizationJobDetailsList) => this.convertApiOptimizationJobDetailsListToOptimizationJobDetailsList(apiOptimizationJobDetailsList));
    }

    async getOptimizationJobDetailsByCustomerIdAndModelSetId(
        bearerToken: BearerToken,
        customerId: string,
        modelSetId: string
    ): Promise<OptimizationJobDetails[]> {
        return await this.heimdallAPI
            .optimizerJobDetailsGet(bearerToken, customerId, modelSetId)
            .then((apiOptimizationJobDetailsList) => this.convertApiOptimizationJobDetailsListToOptimizationJobDetailsList(apiOptimizationJobDetailsList));
    }

    async submitOptimizationJob(
        bearerToken: BearerToken,
        customerId: string,
        modelSetId: string,
        optimizationJobName: string
    ): Promise<void> {
        await this.thanosAPI.optimizationJobsPatch(
            bearerToken,
            customerId,
            modelSetId,
            optimizationJobName
        );
    }

    async cancelOptimizationJob(
        bearerToken: BearerToken,
        customerId: string,
        modelSetId: string,
        optimizationJobName: string
    ): Promise<void> {
        await this.heimdallAPI.optimizerJobCancelJobPost(
            bearerToken,
            customerId,
            modelSetId,
            optimizationJobName,
        );
    }

    async forceOptimizerJobResults(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        bearerToken: BearerToken,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        customerId: string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        modelSetId: string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        optimizationJobName: string
    ): Promise<void> {
        console.error('Not implemented');
    }

    downloadOptimizationJobResult(
        bearerToken: BearerToken,
        customerId: string,
        modelSetId: string,
        optimizationJobName: string
    ): Promise<void> {
        return this.thanosAPI
            .optimizationDownloadLinkPost(
                bearerToken,
                customerId,
                modelSetId,
                optimizationJobName
            )
            .then((downloadLink: string) =>
                this.thanosAPI
                    .downloadLinkGet(downloadLink)
                    .catch((e) => {
                        throw new RepositoryError('downloadLinkGet', RepositoryErrorType.FileNotFound, 'Unable to download file' + e);
                    })
            );
    }

    async deleteOptimizationJob(
        bearerToken: BearerToken,
        customerId: string,
        modelSetId: string,
        optimizationJobName: string
    ): Promise<void> {
        await this.thanosAPI.optimizationJobsDelete(
            bearerToken,
            customerId,
            modelSetId,
            optimizationJobName
        );
    }
}

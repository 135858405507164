import axios from "axios";

import FileDownload from "js-file-download";
import {BearerToken} from "@/domain/bearerTokens/bearerToken";
import {UUID} from "@/domain/common/types";
import {APIError, APIErrorType, InfraErrorMessages} from "@/infra/common/errors";
import {toNumber} from "lodash";
import {extractFileNameFromContentDisposition} from "@/infra/common/utils";


export class HeimdallAPI {
    private readonly baseURL: string;

    constructor(baseUrl: string) {
        this.baseURL = baseUrl;
    }

    handleGenericErrors(method: string, error: any): void {
        if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
            throw new APIError(method, APIErrorType.InvalidAuthToken,
                InfraErrorMessages.InvalidAuthToken);
        } else if (error.code === "ERR_NETWORK") {
            throw new APIError(method, APIErrorType.NetworkError,
                InfraErrorMessages.NetworkError);
        } else {
            throw new APIError(method, APIErrorType.Unknown,
                InfraErrorMessages.Unknown);
        }

    }

    async tokensPost(username: string, password: string): Promise<string> {
        const endpoint = "/tokens";

        const url = this.baseURL + endpoint;

        const params = new URLSearchParams({
            username: username,
            password: password
        }).toString();

        return axios
            .post(
                url,
                params,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }
            )
            .then((r: any) => r.data.access_token)
            .catch(error => {
                    if (
                        error.response.status == 401 &&
                        error.response.data.detail == "Incorrect username/password") {
                        throw new APIError(
                            "tokensPost",
                            APIErrorType.InvalidCredentials,
                            InfraErrorMessages.InvalidUsernamePassword);
                    } else {
                        this.handleGenericErrors("tokensPost", error);
                    }
                }
            );
    }

    async tokensValidatePost(bearerToken: BearerToken): Promise<boolean> {
        const endpoint = "/tokens/validate";

        const url = this.baseURL + endpoint;

        const config: any = {
            method: "post",
            url: url,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            data: {
                access_token: bearerToken.value
            }
        };

        return axios(config)
            .then((r: any) => {
                if (r.data.message == "valid") {
                    return true;
                } else {
                    return false;
                }
            })
            .catch(() => {
                throw new APIError("tokensValidatePost", APIErrorType.Unknown,
                    InfraErrorMessages.Unknown);
            });
    }

    async forecastsInputFilesPost(
        bearerToken: BearerToken,
        customerUUID: UUID,
        inputFile: any
    ): Promise<any> {
        const endpoint = "/forecasts/input_files";

        const url = this.baseURL + endpoint;

        const form = new FormData();
        form.append("customer_uuid", customerUUID);
        form.append("input_file", inputFile);

        return axios
            .post(url, form, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: "Bearer " + bearerToken.value
                }
            })
            .then((r: any) => r)
            .catch(e => this.handleGenericErrors("forecastsInputFilesMetadataPost", e));
    }

    // TODO: Add a data layer between the APIs and the Vue logic
    // TODO: That way if the APIs change don't have to change the Vue logic & the API logic
    async forecastsInputFilesMetadataPost(
        bearerToken: BearerToken,
        inputFile: any
    ): Promise<any> {
        const endpoint = "/forecasts/input_files/metadata";

        const url = this.baseURL + endpoint;

        const form = new FormData();
        form.append("input_file", inputFile);

        return axios
            .post(url, form, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: "Bearer " + bearerToken.value
                }
            })
            .then((r: any) => r)
            .catch(e => {
                if (e.code === "ERR_BAD_REQUEST" && e.response.status === 400) {
                    throw new APIError("forecastsInputFilesMetadataPost", APIErrorType.InvalidInput,
                        e.response.data.detail);
                }
                this.handleGenericErrors("forecastsInputFilesMetadataPost", e);
            });
    }

    async forecastsGetByForecastUUID(
        bearerToken: BearerToken,
        forecastUUID: UUID
    ): Promise<any> {
        const endpoint = "/forecasts";

        const url = this.baseURL + endpoint;

        const config: any = {
            method: "get",
            url: url,
            params: {forecast_uuid: forecastUUID},
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: "Bearer " + bearerToken.value
            }
        };

        return axios(config)
            .then((r: any) => r["data"])
            .catch(e => this.handleGenericErrors("forecastsGetByForecastUUID", e));
    }

    async forecastsGetByCustomerUUID(
        bearerToken: BearerToken,
        customerUUID: UUID
    ): Promise<any> {
        const endpoint = "/forecasts";

        const url = this.baseURL + endpoint;

        const config: any = {
            method: "get",
            url: url,
            params: {customer_uuid: customerUUID},
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: "Bearer " + bearerToken.value
            }
        };

        return axios(config)
            .then((r: any) => r["data"])
            .catch(e => this.handleGenericErrors("forecastsGetByCustomerUUID", e));
    }

    async forecastsPost(
        bearerToken: BearerToken,
        customerUUID: UUID
    ): Promise<any> {
        const endpoint = "/forecasts";

        const url = this.baseURL + endpoint;

        const data = JSON.stringify({customer_uuid: customerUUID});

        const config: any = {
            method: "post",
            url: url,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: "Bearer " + bearerToken.value
            },
            data: data
        };

        return axios(config)
            .then((r: any) => r)
            .catch(e => {
                if (e.response.status === 404) {
                    throw new APIError("forecastsInputFilesMetadataPost", APIErrorType.UnableToStartForecast,
                        e.response.data.detail);
                } else {
                    this.handleGenericErrors("forecastsPost", e);
                }
            });
    }


    async customersGet(bearerToken: BearerToken): Promise<any> {
        const endpoint = "/customers";

        const url = this.baseURL + endpoint;

        const config: any = {
            method: "get",
            url: url,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value
            }
        };

        return axios(config)
            .then((r: any) => r["data"])
            .catch(e => this.handleGenericErrors("customersGet", e));
    }

    async getForecastsOutputFiles(
        bearerToken: BearerToken,
        forecastUUID: UUID
    ): Promise<any> {
        const endpoint = "/forecasts/output_files";

        const url = this.baseURL + endpoint;

        const config: any = {
            method: "get",
            url: url,
            params: {forecast_uuid: forecastUUID},
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value
            }
        };

        return axios(config)
            .then((response: any) => {
                FileDownload(response.data.file_data, response.data.file_name);
            })
            .catch(e => this.handleGenericErrors("getForecastsOutputFiles", e));
    }

    async filesPost(bearerToken: BearerToken, file: File): Promise<string> {
        const endpoint = "/files";

        const url = this.baseURL + endpoint;

        const form = new FormData();
        form.append("file", file);
        return await axios
            .post(url, form, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: "Bearer " + bearerToken.value,
                },
            })
            .then((r: any) => {
                return r.data.file_uuid; // fileId
            })
            .catch((e: any) => {
                return e;
            });
    }

    /**
     * Generic method for downloading a file from various Heimdall API endpoints.
     *
     * @param bearerToken - The bearer token to use for authentication.
     * @param url - The URL to download the file from.
     * @param endpointIdentifier - The name of the endpoint that is being called. This is used for error handling.
     * @param filename - The name to assign to the file being downloaded.
     * @param progressCallback - An optional callback function that will be called throughout the download
     * to update the upstream on progress.
     */
    async downloadFileWithCallback(
        bearerToken: BearerToken,
        url: string,
        endpointIdentifier: string,
        filename?: string,
        progressCallback?: (progress: number) => void
    ): Promise<void> {
        // Start progress at 0%.
        if (progressCallback) {
            progressCallback(0);
        }

        const headers = new Headers();
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Authorization", "Bearer " + bearerToken.value);

        const response = await fetch(url, {
            method: 'GET',
            headers: headers,
        });

        if (!response.ok) {
            throw new APIError(endpointIdentifier, APIErrorType.MalformedResponse,
                InfraErrorMessages.NetworkResponseWasNotOk);
        }

        if (!response.body) {
            throw new APIError(endpointIdentifier, APIErrorType.MalformedResponse,
                InfraErrorMessages.MissingResponseBody);
        }

        const contentLength: string | null = response.headers.get('Content-Length');
        if (!contentLength) {
            throw new APIError(endpointIdentifier, APIErrorType.MalformedResponse,
                InfraErrorMessages.MissingContentLengthHeader);
        }

        // Determine file name to utilize.
        // Function input file name is prioritized over the content disposition header filename.
        let filenameToUse = filename;
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentDisposition) {
            const extractedFilename = extractFileNameFromContentDisposition(contentDisposition);
            if (extractedFilename) {
                filenameToUse = extractedFilename;
            }
        }

        // Track download progress
        let numberOfBytesDownloaded = 0;
        const reader = response.body.getReader();
        const chunks: Uint8Array[] = [];

        // Download the file in chunks and then combine them into a single blob for the browser to download
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const {done, value} = await reader.read();

            // Terminate the loop once we finish reading the stream
            if (done) {
                break;
            }

            // Handle the stream chunk
            chunks.push(value);
            numberOfBytesDownloaded += value.length;

            // Calculate the download percentage
            const percentageDownloaded = (numberOfBytesDownloaded / toNumber(contentLength)) * 100;
            if (progressCallback) {
                progressCallback(Math.round(percentageDownloaded));
            }
        }

        // Once we are done reading the stream, we can download the file
        const blob = new Blob(chunks);
        const tempFileUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = tempFileUrl;
        a.download = filenameToUse ?? 'download';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(tempFileUrl);
        document.body.removeChild(a);
    }

    /**
     * This method is used to download a file from the Heimdall API filesGet endpoint.
     *
     * @param bearerToken - The bearer token to use for authentication.
     * @param fileUUID - The UUID of the file to download.
     * @param progressCallback - An optional callback function that will be called throughout the download
     * to update the upstream on progress.
     */
    async filesGet(
        bearerToken: BearerToken,
        fileUUID: UUID,
        filename?: string,
        progressCallback?: (progress: number) => void
    ): Promise<void> {
        const endpointIdentifier = 'filesGet';
        const endpoint = "/files";
        const url = this.baseURL + endpoint + `?file_uuid=${fileUUID}`
        const outputFilename = filename ?? fileUUID;
        return this.downloadFileWithCallback(bearerToken, url, endpointIdentifier, outputFilename, progressCallback);
    }

    /**
     * This method is used to download a customer configuration file
     *
     * @param bearerToken - The bearer token to use for authentication.
     * @param customerUUID - The UUID of the customer to download the configuration file for.
     */
    async customerConfigurationFileGet(
        bearerToken: BearerToken,
        customerUUID: UUID,
    ): Promise<void> {
        const endpointIdentifier = 'tyr_api';
        const url = this.baseURL + `/passthrough/tyr_api/customer_configuration_xlsx_file?customer_uuid=${customerUUID}`
        return this.downloadFileWithCallback(bearerToken, url, endpointIdentifier);
    }

    async customerConfigurationFilePost(
        bearerToken: BearerToken,
        file: File,
        customerUUID: UUID,
    ): Promise<string> {
        const endpoint = "/passthrough/tyr_api/customer_configuration_xlsx_file";

        const url = this.baseURL + endpoint;

        const form = new FormData();
        form.append("customer_configuration_xlsx_file", file);
        form.append("customer_uuid", customerUUID);
        return await axios
            .post(url, form, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: "Bearer " + bearerToken.value,
                },
            })
            .then((r: any) => {
                return r.data.file_uuid; // fileId
            })
            .catch((e: any) => {
                return e;
            });
    }

    async jobsSummariesGet(
        bearerToken: BearerToken,
    ): Promise<any> {
        const endpoint = "/passthrough/tyr_api/jobs_summaries";

        const url = this.baseURL + endpoint;

        const config: any = {
            method: "get",
            url: url,
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: "Bearer " + bearerToken.value
            }
        };

        return axios(config)
            .then((r: any) => {
                    return r["data"]
                }
            )
            .catch(e => this.handleGenericErrors("jobsSummariesGet", e));
    }

    async optimizerJobDetailsGet(
        bearerToken: BearerToken,
        customerId?: string,
        modelSetId?: string,
        jobName?: string,
    ): Promise<any> {
        const endpoint = '/passthrough/tyr_api/optimizer_jobs/optimizer_job_details';
        const url = this.baseURL + endpoint;
        const config: any = {
            method: "get",
            url: url,
            params: {
                job_name: jobName,
                customer_id: customerId,
                model_set_id: modelSetId,
            },
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: "Bearer " + bearerToken.value
            }
        };

        return axios(config)
            .then((r: any) => {
                    return r["data"]
                }
            )
            .catch(e => this.handleGenericErrors("optimizerJobDetailsGet", e));
    }

    async optimizerJobCancelJobPost(
        bearerToken: BearerToken,
        clientId: string,
        modelSetId: string,
        jobName: string,
    ): Promise<void> {
        const endpoint = '/passthrough/tyr_api/optimizer_jobs/cancel_job';
        const url = this.baseURL + endpoint;

        const formData = new FormData();
        formData.append("client_id", clientId);
        formData.append("model_set_id", modelSetId);
        formData.append("job_name", jobName);

        return axios.postForm(url, {
                client_id: clientId,
                model_set_id: modelSetId,
                job_name: jobName,
            },
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: "Bearer " + bearerToken.value,
                }
            })
            .then(() => {
            })
            .catch(e => {
                this.handleGenericErrors("optimizerJobCancelJobPost", e)
            });
    }

    async filesMetadataGet(
        bearerToken: BearerToken,
        fileUUID: UUID,
    ): Promise<any> {
        const endpoint = "/file_metadata";

        const url = this.baseURL + endpoint + `?file_uuid=${fileUUID}`;

        const config: any = {
            method: "get",
            url: url,
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: "Bearer " + bearerToken.value
            }
        };

        return axios(config)
            .then((r: any) => {
                    return r["data"]
                }
            )
            .catch(e => this.handleGenericErrors("filesMetadataGet", e));
    }
}

import {
  createRouter, createWebHashHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw
} from "vue-router";

import DashboardView from "../views/DashboardView.vue";
import { APIBearerTokensRepository } from "@/infra/bearerTokens/apiBearerTokensRepository";
import store from "@/service/store";
import { getConfig } from "@/common/config";
import { HeimdallAPI } from "@/infra/api/heimdall/heimdall";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    component: () => import(`../layouts/default/Index.vue`),
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: DashboardView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: DashboardView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/login",
        name: "LoginView",
        component: () => import("../views/LoginView.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/environment",
        name: "EnvironmentView",
        component: () => import("../views/EnvironmentView.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "/error",
        name: "ErrorView",
        component: () => import("../views/ErrorView.vue"),
        meta: {
          requiresAuth: false
        }
      },
      // Processing Jobs
      {
        path: "/processing-jobs",
        name: "ProcessingJobsView",
        component: () =>
          import("../views/processingJobs/ProcessingJobsView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/new-job",
        name: "NewJobView",
        component: () => import("../views/processingJobs/NewJobView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/job-statuses",
        name: "JobStatusesView",
        component: () => import("../views/processingJobs/JobStatusesView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/forecast-job",
        name: "ForecastJobView",
        component: () => import("../views/processingJobs/ForecastJobView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/job-confirmation",
        name: "JobConfirmationView",
        component: () =>
          import("../views/processingJobs/JobConfirmationView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      // Legacy Optimizer
      {
        path: "/legacy-optimizer",
        name: "LegacyOptimizerView",
        component: () =>
          import("../views/legacyOptimizer/LegacyOptimizerView.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
});

// Send user to login page if they are logged out
router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    if (to.fullPath === "/") {
      next("/dashboard");
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      const config = getConfig();
      const api = new HeimdallAPI(config.heimdallEndpoint);
      const bearerTokenRepository = new APIBearerTokensRepository(api);
      const bearerToken = store.getters.bearerToken;
      if (bearerToken) {
        await bearerTokenRepository.isBearerTokenValid(bearerToken)
          .then((isValid) => isValid ? next() : next("login"))
          .catch(() => next("/login"));
      } else {
        next("/login"); // No token found
      }
    } else {
      next(); // Page doesn't need auth
    }
  }
);

export default router;

import { UUID } from "@/domain/common/types";

interface ForecastJobParams {
  name: string;
  type: string;
  builderStatus: string;
  detail: string;
  remainingSubJobs: number;
  createdTimestamp: string;
  builderJobID: UUID;
  optimizerJobID: UUID;
  finalizerJobID: UUID;
  status: string;
}

export class ForecastJob {
  name: string;
  type: string;
  builderStatus: string;
  detail: string;
  remainingSubJobs: number;
  createdTimestamp: string;
  builderJobID: UUID;
  optimizerJobID: UUID;
  finalizerJobID: UUID;
  status: string;

  constructor(params: ForecastJobParams) {
    this.name = params.name;
    this.type = params.type;
    this.builderStatus = params.builderStatus;
    this.detail = params.detail;
    this.remainingSubJobs = params.remainingSubJobs;
    this.createdTimestamp = params.createdTimestamp;
    this.builderJobID = params.builderJobID;
    this.optimizerJobID = params.optimizerJobID;
    this.finalizerJobID = params.finalizerJobID;
    this.status = params.status;
  }
}

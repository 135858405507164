import {ThanosAPIFile} from "@/infra/api/thanos_api/models";
import {UUID} from "@/domain/common/types";

export class S3File {
    fileUUID: UUID;
    type: string;
    filename: string;

    constructor(fileUUID: UUID, type: string, filename: string) {
        this.fileUUID = fileUUID;
        this.type = type;
        this.filename = filename;
    }
}

export function convertThanosAPIFileToS3File(
    thanosAPIFile: ThanosAPIFile
): S3File | undefined {
    if (thanosAPIFile === undefined || thanosAPIFile.file_name === undefined || thanosAPIFile.file_name === "" ||
        thanosAPIFile.file_type === undefined || thanosAPIFile.s3_path === undefined || thanosAPIFile.s3_path === "") {
        return undefined;
    }
    return {
        fileUUID: thanosAPIFile.s3_path,
        type: thanosAPIFile.file_type,
        filename: thanosAPIFile.file_name,
    };
}

export function convertS3FileToThanosAPIFile(s3File: S3File): ThanosAPIFile {
    return {
        s3_path: s3File.fileUUID,
        file_type: s3File.type,
        file_name: s3File.filename,
    };
}

import {UUID} from "@/domain/common/types";
import {S3File} from "@/domain/legacyOptimizer/s3File/s3File";

/* Based on Thanos Model */
interface OptimizationJobParams {
    name: string;
    builderStatus: string;
    detail: string;
    remainingSubJobs: number;
    createdTimestamp: string;
    builderJobID?: UUID;
    optimizerJobID?: UUID;
    finalizerJobID?: UUID;
    status: string;
    itemsFile?: S3File;
    forecastFile?: S3File;
}

/* Based on Thanos Model */
export class OptimizationJob {
    name: string;
    builderStatus: string;
    detail: string;
    remainingSubJobs: number;
    createdTimestamp: string;
    builderJobID?: UUID;
    optimizerJobID?: UUID;
    finalizerJobID?: UUID;
    status: string;
    itemsFile?: S3File;
    forecastFile?: S3File;

    constructor(params: OptimizationJobParams) {
        this.name = params.name;
        this.builderStatus = params.builderStatus;
        this.detail = params.detail;
        this.remainingSubJobs = params.remainingSubJobs;
        this.createdTimestamp = params.createdTimestamp;
        this.builderJobID = params.builderJobID;
        this.optimizerJobID = params.optimizerJobID;
        this.finalizerJobID = params.finalizerJobID;
        this.status = params.status;
        this.itemsFile = params.itemsFile;
        this.forecastFile = params.forecastFile;
    }
}

/* Based on Tyr API Model */
interface OptimizationJobDetailsParams {
    jobName: string;
    status: string;
    totalJobItems?: number;
    optimizerBuildJobId?: UUID;
    optimizerBuildJobStatus?: string;
    optimizerOptimizeJobId?: UUID;
    optimizerOptimizeJobStatus?: string;
    optimizerOptimizeJobNumberOfSubJobsStarting?: number;
    optimizerOptimizeJobNumberOfSubJobsFailed?: number;
    optimizerOptimizeJobNumberOfSubJobsRunning?: number;
    optimizerOptimizeJobNumberOfSubJobsSucceeded?: number;
    optimizerOptimizeJobNumberOfSubJobsRunnable?: number;
    optimizerOptimizeJobNumberOfSubJobsSubmitted?: number;
    optimizerOptimizeJobNumberOfSubJobsPending?: number;
    optimizerFinalizeJobId?: UUID;
    optimizerFinalizeJobStatus?: string;
}

/* Based on Tyr API Model */
export class OptimizationJobDetails {
    jobName: string;
    status: string;
    totalJobItems?: number;
    optimizerBuildJobId?: UUID;
    optimizerBuildJobStatus?: string;
    optimizerOptimizeJobId?: UUID;
    optimizerOptimizeJobStatus?: string;
    optimizerOptimizeJobNumberOfSubJobsStarting?: number;
    optimizerOptimizeJobNumberOfSubJobsFailed?: number;
    optimizerOptimizeJobNumberOfSubJobsRunning?: number;
    optimizerOptimizeJobNumberOfSubJobsSucceeded?: number;
    optimizerOptimizeJobNumberOfSubJobsRunnable?: number;
    optimizerOptimizeJobNumberOfSubJobsSubmitted?: number;
    optimizerOptimizeJobNumberOfSubJobsPending?: number;
    optimizerFinalizeJobId?: UUID;
    optimizerFinalizeJobStatus?: string;

    constructor(params: OptimizationJobDetailsParams) {
        this.jobName = params.jobName;
        this.status = params.status;
        this.totalJobItems = params.totalJobItems;
        this.optimizerBuildJobId = params.optimizerBuildJobId;
        this.optimizerBuildJobStatus = params.optimizerBuildJobStatus;
        this.optimizerOptimizeJobId = params.optimizerOptimizeJobId;
        this.optimizerOptimizeJobStatus = params.optimizerOptimizeJobStatus;
        this.optimizerOptimizeJobNumberOfSubJobsStarting = params.optimizerOptimizeJobNumberOfSubJobsStarting;
        this.optimizerOptimizeJobNumberOfSubJobsFailed = params.optimizerOptimizeJobNumberOfSubJobsFailed;
        this.optimizerOptimizeJobNumberOfSubJobsRunning = params.optimizerOptimizeJobNumberOfSubJobsRunning;
        this.optimizerOptimizeJobNumberOfSubJobsSucceeded = params.optimizerOptimizeJobNumberOfSubJobsSucceeded;
        this.optimizerOptimizeJobNumberOfSubJobsRunnable = params.optimizerOptimizeJobNumberOfSubJobsRunnable;
        this.optimizerOptimizeJobNumberOfSubJobsSubmitted = params.optimizerOptimizeJobNumberOfSubJobsSubmitted;
        this.optimizerOptimizeJobNumberOfSubJobsPending = params.optimizerOptimizeJobNumberOfSubJobsPending;
        this.optimizerFinalizeJobId = params.optimizerFinalizeJobId;
        this.optimizerFinalizeJobStatus = params.optimizerFinalizeJobStatus;
    }
}
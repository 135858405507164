export enum ServiceErrorMessages {
  Unknown = "An unknown error has occurred",
  InvalidUsernamePassword = "Invalid username/password",
  InvalidAuthToken = "Invalid auth token",
  NetworkError = "A network error has occurred",
}

export enum ServiceErrorType {
  Unknown = "Unknown",
  UnableToLoadData = "UnableToLoadData",
  UnableToObtainBearerToken = "UnableToObtainBearerToken",
  NetworkError = "NetworkError",
  InvalidUsernameOrPassword = "InvalidUsernameOrPassword",
}

export class ServiceError extends Error {
  method: string;
  errorType: ServiceErrorType;
  constructor(method: string, type: ServiceErrorType, message: string) {
    super(message);
    this.method = method;
    this.errorType = type;
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}

import { BearerToken } from "@/domain/bearerTokens/bearerToken";
import { CustomerConfig } from "@/domain/legacyOptimizer/customerConfig/customerConfig";
import {
  AbstractCustomerConfigurationRepository
} from "@/infra/legacyOptimizer/customerConfigurationRepository/abstractCustomerConfigurationRepository";
import { ThanosAPI } from "@/infra/api/thanos_api/thanos_api";
import { ScenarioRule } from "@/domain/legacyOptimizer/scenarioRule/scenarioRule";
import { ItemDeathRule } from "@/domain/legacyOptimizer/itemDeathRules/itemDeathRules";
import {
  ThanosAPIItemDeathRule, ThanosAPIItemDeathRulesObject, ThanosAPIScenarioRule
} from "@/infra/api/thanos_api/models";

export class APICustomerConfigurationRepository implements AbstractCustomerConfigurationRepository {
  private readonly api: ThanosAPI;

  constructor(api: ThanosAPI) {
    this.api = api;
  }

  async createCustomerConfigByCustomerId(
    bearerToken: BearerToken,
    customerId: string
  ): Promise<void> {
    await this.api.clientsPost(bearerToken, customerId);
  }

  async updateCustomerConfigByCustomerId(
    bearerToken: BearerToken,
    customerId: string,
    clientConfig: CustomerConfig
  ): Promise<void> {
    const itemDeathRules: ThanosAPIItemDeathRule[] =
      clientConfig.itemDeathRules.map((itemDeathRule) => {
        return {
          txn_last_120d: itemDeathRule.transactionsLast120Days,
          StdDeath: itemDeathRule.standardDeath,
          NoDeath: itemDeathRule.noDeath,
        };
      });
    const itemDeathRulesObject: ThanosAPIItemDeathRulesObject = {
      columns: ["txn_last_120d", "StdDeath", "NoDeath"],
      data: itemDeathRules,
    };

    const scenarioRules: ThanosAPIScenarioRule[] =
      clientConfig.scenarioRules.map((scenarioRule) => {
        return {
          name: scenarioRule.name,
          item_death_rule: scenarioRule.itemDeathRuleName,
          annual_variable_holding_cost: scenarioRule.annualVariableHoldingCost,
          backorder_line_fixed_cost: scenarioRule.backorderLineCostFixed,
          backorder_variable_cost: scenarioRule.backorderCostVariable,
          receipt_line_fixed_cost: scenarioRule.receiptLineCostFixed,
          spill_line_fixed_cost: scenarioRule.spillLineCostFixed,
          spill_variable_cost: scenarioRule.spillLineCostVariable,
          stockout_spill_rate: scenarioRule.stockoutSpillRate,
        };
      });

    await this.api.clientsPatch(
      bearerToken,
      customerId,
      itemDeathRulesObject,
      scenarioRules,
      clientConfig.monthlyDecayLeadTime,
      clientConfig.monthlyDecaySalePrice,
      clientConfig.monthlyDecaySalesOrders,
      clientConfig.monthlyDecaySalesQty,
      clientConfig.simDaysPerTrial,
      clientConfig.simMaxTrials,
      clientConfig.simMinTrials,
      clientConfig.simNeighborhood,
      clientConfig.simTrialsThresholdHigh,
      clientConfig.simTrialsThresholdLow
    );
  }

  async getCustomerConfigByCustomerId(
    bearerToken: BearerToken,
    customerId: string
  ): Promise<CustomerConfig> {
    return this.api.clientsGet(bearerToken, customerId).then((clients) => {
      if (clients.length == 0) {
        throw Error(
          `Unable to retrieve Basic Params for client with customerId: ${customerId}`
        );
      }
      const client = clients[0];
      const attributes = client.attributes;

      const apiScenarioRules: ThanosAPIScenarioRule[] =
        attributes.scenario_rules;
      const scenarioRules: ScenarioRule[] = apiScenarioRules.map(
        (apiScenarioRule) =>
          new ScenarioRule({
            name: apiScenarioRule.name,
            itemDeathRuleName: apiScenarioRule.item_death_rule,
            annualVariableHoldingCost:
              apiScenarioRule.annual_variable_holding_cost,
            receiptLineCostFixed: apiScenarioRule.receipt_line_fixed_cost,
            backorderLineCostFixed: apiScenarioRule.backorder_line_fixed_cost,
            backorderCostVariable: apiScenarioRule.backorder_variable_cost,
            spillLineCostFixed: apiScenarioRule.spill_line_fixed_cost,
            spillLineCostVariable: apiScenarioRule.spill_variable_cost,
            stockoutSpillRate: apiScenarioRule.stockout_spill_rate,
          })
      );

      const itemDeathRulesObject: ThanosAPIItemDeathRulesObject =
        attributes.item_death_rules;
      const apiItemDeathRules = itemDeathRulesObject.data;

      const itemDeathRules: ItemDeathRule[] = apiItemDeathRules.map(
        (apiItemDeathRule) =>
          new ItemDeathRule({
            transactionsLast120Days: apiItemDeathRule.txn_last_120d,
            standardDeath: apiItemDeathRule.StdDeath,
            noDeath: apiItemDeathRule.NoDeath,
          })
      );

      const clientConfig = new CustomerConfig({
        monthlyDecayLeadTime: attributes.monthly_decay_lead_time,
        monthlyDecaySalePrice: attributes.monthly_decay_sale_price,
        monthlyDecaySalesOrders: attributes.monthly_decay_sales_orders,
        monthlyDecaySalesQty: attributes.monthly_decay_sales_qty,
        simDaysPerTrial: attributes.sim_days_per_trial,
        simMaxTrials: attributes.sim_max_trials,
        simMinTrials: attributes.sim_min_trials,
        simNeighborhood: attributes.sim_neighborhood,
        simTrialsThresholdHigh: attributes.sim_trials_threshold_high,
        simTrialsThresholdLow: attributes.sim_trials_threshold_low,
        itemDeathRules,
        scenarioRules,
      });
      return clientConfig;
    });
  }
}

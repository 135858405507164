<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import {defineComponent, provide} from "vue";
import {getConfig} from "@/common/config";
import {ThanosAPI} from "@/infra/api/thanos_api/thanos_api";
import {HeimdallAPI} from "@/infra/api/heimdall/heimdall";
import {
  CUSTOMER_CONFIGURATION_FILES_REPOSITORY,
  THANOS_CUSTOMER_CONFIGURATION_REPOSITORY,
  CUSTOMERS_REPOSITORY, THANOS_FORECAST_JOBS_REPOSITORY,
  HEIMDALL_FILES_REPOSITORY,
  THANOS_JOBS_SUMMARIES_REPOSITORY,
  THANOS_MODEL_SETS_REPOSITORY, OPTIMIZATION_JOBS_REPOSITORY,
  THANOS_FILES_REPOSITORY,
} from "@/service/repositories/repositories";
import {APIS3FilesRepository} from "@/infra/files/filesRepository/apiIS3FilesRepository";
import {
  APICustomerConfigurationFilesRepository
} from "@/infra/files/filesRepository/apiCustomerConfigurationFilesRepository";
import {
  APICustomerConfigurationRepository
} from "@/infra/legacyOptimizer/customerConfigurationRepository/apiCustomerConfigurationRepository";
import {ApiJobsSummariesRepository} from "@/infra/legacyOptimizer/jobsSummariesRepository/apiJobsSummariesRepository";
import {APICustomersRepository} from "@/infra/customers/apiCustomersRepository";
import {APIModelSetsRepository} from "@/infra/legacyOptimizer/modelSetsRepository/apiModelSetsRepository";
import {APIForecastJobsRepository} from "@/infra/legacyOptimizer/forecastJobsRepository/apiForecastJobsRepository";
import {
  APIOptimizationJobsRepository
} from "@/infra/legacyOptimizer/optimizationJobsRepository/apiOptimizationJobsRepository";

const env = getConfig().environment;
let title;
if (env === "local" || env === "test") {
  title = "Vale | " + env;
} else {
  title = "Vale";
}

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "vale",
  setup() {
    const config = getConfig();
    const thanosAPI = new ThanosAPI(config.thanosAPIEndpoint);
    const heimdallAPI = new HeimdallAPI(config.heimdallEndpoint);

    // Thanos API
    const thanosFilesRepository = new APIS3FilesRepository(thanosAPI);
    provide(THANOS_FILES_REPOSITORY, thanosFilesRepository);
    const thanosCustomerConfigurationRepository = new APICustomerConfigurationRepository(thanosAPI);
    provide(THANOS_CUSTOMER_CONFIGURATION_REPOSITORY, thanosCustomerConfigurationRepository);
    const modelSetsRepository = new APIModelSetsRepository(thanosAPI);
    provide(THANOS_MODEL_SETS_REPOSITORY, modelSetsRepository);
    const forecastJobsRepository = new APIForecastJobsRepository(thanosAPI);
    provide(THANOS_FORECAST_JOBS_REPOSITORY, forecastJobsRepository);
    const optimizationJobsRepository = new APIOptimizationJobsRepository(thanosAPI, heimdallAPI);
    provide(OPTIMIZATION_JOBS_REPOSITORY, optimizationJobsRepository);

    // Heimdall API
    const heimdallFilesRepository = new APIS3FilesRepository(heimdallAPI);
    provide(HEIMDALL_FILES_REPOSITORY, heimdallFilesRepository);
    const heimdallCustomerConfigurationFilesRepository = new APICustomerConfigurationFilesRepository(heimdallAPI);
    provide(CUSTOMER_CONFIGURATION_FILES_REPOSITORY, heimdallCustomerConfigurationFilesRepository);
    const jobsSummariesRepository = new ApiJobsSummariesRepository(heimdallAPI);
    provide(THANOS_JOBS_SUMMARIES_REPOSITORY, jobsSummariesRepository);
    const customersRepository = new APICustomersRepository(heimdallAPI);
    provide(CUSTOMERS_REPOSITORY, customersRepository);

    return {};
  },
  metaInfo: {
    title: title,
    titleTemplate: "Hydrian | %s",
    htmlAttrs: {lang: "en"},
    meta: [
      {charset: "utf-8"},
      {name: "viewport", content: "width=device-width, initial-scale=1"},
    ]
  }
});
</script>

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: "#49BFBA",
          primaryVariant: "#86D5D1",
          secondary: "#2B6B7A",
          secondaryVariant: "#59B1C5",
          background: "#FFFFFF",
          backgroundVariant: "#F2E3E7",
          backgroundVariantTwo: "#dedede",
          accent: "#D6423D",
        },
      },
    },
  },
});

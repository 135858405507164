import {InjectionKey} from "vue";
import {APIS3FilesRepository} from "@/infra/files/filesRepository/apiIS3FilesRepository";
import {
    APICustomerConfigurationFilesRepository
} from "@/infra/files/filesRepository/apiCustomerConfigurationFilesRepository";
import {
    APICustomerConfigurationRepository
} from "@/infra/legacyOptimizer/customerConfigurationRepository/apiCustomerConfigurationRepository";
import {
    AbstractJobsSummariesRepository
} from "@/infra/legacyOptimizer/jobsSummariesRepository/abstractJobsSummariesRepository";
import {AbstractCustomersRepository} from "@/infra/customers/abstractCustomersRepository";
import {APIModelSetsRepository} from "@/infra/legacyOptimizer/modelSetsRepository/apiModelSetsRepository";
import {
    AbstractFileDownloadRepository,
    AbstractFileUploadRepository
} from "@/infra/files/filesRepository/abstractFilesRepository";
import {APIForecastJobsRepository} from "@/infra/legacyOptimizer/forecastJobsRepository/apiForecastJobsRepository";
import {
    APIOptimizationJobsRepository
} from "@/infra/legacyOptimizer/optimizationJobsRepository/apiOptimizationJobsRepository";
import {AbstractForecastsRepository} from "@/infra/processingJobs/forecasts/abstractForecastsRepository";
import {
    AbstractForecastInputFilesRepository
} from "@/infra/processingJobs/forecastInputFile/abstractForecastInputFilesRepository";
import {APIJobsRepository} from "@/infra/processingJobs/jobs/apiJobsRepository";


export const CUSTOMERS_REPOSITORY: InjectionKey<AbstractCustomersRepository> = Symbol('CUSTOMERS_REPOSITORY');
export const FILE_DOWNLOAD_REPOSITORY: InjectionKey<AbstractFileDownloadRepository> = Symbol('FILE_DOWNLOAD_REPOSITORY');
export const FILE_UPLOAD_REPOSITORY: InjectionKey<AbstractFileUploadRepository> = Symbol('FILE_UPLOAD_REPOSITORY');
export const CUSTOMER_CONFIGURATION_FILES_REPOSITORY: InjectionKey<APICustomerConfigurationFilesRepository> = Symbol("CUSTOMER_CONFIGURATION_FILES_REPOSITORY");
export const FORECASTS_REPOSITORY: InjectionKey<AbstractForecastsRepository> = Symbol("FORECASTS_REPOSITORY");
export const FORECAST_INPUT_FILES_REPOSITORY: InjectionKey<AbstractForecastInputFilesRepository> = Symbol("FORECAST_INPUT_FILES_REPOSITORY");
export const JOBS_REPOSITORY: InjectionKey<APIJobsRepository> = Symbol("JOBS_REPOSITORY");

// Special case (we have two APIS3FilesRepository repositories - HEIMDALL & THANOS)
export const HEIMDALL_FILES_REPOSITORY: InjectionKey<APIS3FilesRepository> = Symbol("HEIMDALL_FILES_REPOSITORY");

// Legacy Optimizer Repositories
// Marked explicitly as Thanos - these will all be replaced by Thor repositories
export const THANOS_JOBS_SUMMARIES_REPOSITORY: InjectionKey<AbstractJobsSummariesRepository> = Symbol('THANOS_JOBS_SUMMARIES_REPOSITORY');
export const THANOS_FILES_REPOSITORY: InjectionKey<APIS3FilesRepository> = Symbol("THANOS_FILES_REPOSITORY");
export const THANOS_CUSTOMER_CONFIGURATION_REPOSITORY: InjectionKey<APICustomerConfigurationRepository> = Symbol("THANOS_CUSTOMER_CONFIGURATION_REPOSITORY");
export const THANOS_MODEL_SETS_REPOSITORY: InjectionKey<APIModelSetsRepository> = Symbol("THANOS_MODEL_SETS_REPOSITORY");
export const THANOS_FORECAST_JOBS_REPOSITORY: InjectionKey<APIForecastJobsRepository> = Symbol("THANOS_FORECAST_JOBS_REPOSITORY");
export const OPTIMIZATION_JOBS_REPOSITORY: InjectionKey<APIOptimizationJobsRepository> = Symbol("OPTIMIZATION_JOBS_REPOSITORY");

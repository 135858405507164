export interface ItemDeathRuleParams {
  transactionsLast120Days: number;
  standardDeath: number;
  noDeath: number;
}

export class ItemDeathRule {
  transactionsLast120Days: number;
  standardDeath: number;
  noDeath: number;

  constructor(params: ItemDeathRuleParams) {
    this.transactionsLast120Days = params.transactionsLast120Days;
    this.standardDeath = params.standardDeath;
    this.noDeath = params.noDeath;
  }
}

export class Config {
  environment: string;
  heimdallEndpoint: string;
  thanosAPIEndpoint: string;

  constructor(
    environment: string,
    heimdallEndpoint: string,
    thanosAPIEndpoint: string
  ) {
    this.environment = environment;
    this.heimdallEndpoint = heimdallEndpoint;
    this.thanosAPIEndpoint = thanosAPIEndpoint;
  }
}

enum Environment {
  Local = "local",
  Test = "test",
  Prod = "prod",
}

function getEnvironment(): Environment {
  // TODO: Make this pull from AWS secrets instead
  const env = process.env["ENVIRONMENT"];
  if (env) {
    // Running in NodeJS environment
    return env as Environment;
  } else {
    // Running in browser environment
    const url = window ? window.location.href : "";
    if (url.includes("test.vale")) {
      return Environment.Test;
    } else if (url.includes("vale")) {
      return Environment.Prod;
    } else {
      return Environment.Local;
    }
  }
}

export function getConfig(): Config {
  const environment: Environment = getEnvironment();
  let configJSON;
  switch (environment) {
    case Environment.Local:
      configJSON = require("@/configs/local.json");
      break;

    case Environment.Test:
      configJSON = require("@/configs/test.json");
      break;

    case Environment.Prod:
      configJSON = require("@/configs/prod.json");
      break;

    default:
      console.error(`Invalid environment detected: ${environment}`);
  }
  return new Config(
    configJSON.ENVIRONMENT,
    configJSON.HEIMDALL_ENDPOINT,
    configJSON.THANOS_API_ENDPOINT
  );
}

import axios from "axios";
import {BearerToken} from "@/domain/bearerTokens/bearerToken";
import {
    ThanosAPIClient, ThanosAPIForecastJob, ThanosAPIItemDeathRulesObject, ThanosAPIModelSet, ThanosAPIModelSetFiles,
    ThanosAPIOptimizationJob, ThanosAPIOptimizationJobFiles, ThanosAPIScenarioRule
} from "@/infra/api/thanos_api/models";
import FormData from "form-data";
import {UUID} from "@/domain/common/types";

export class ThanosAPI {
    private readonly baseURL: string;

    constructor(baseUrl: string) {
        this.baseURL = baseUrl;
    }

    /** Clients */
    async clientsPost(
        bearerToken: BearerToken,
        clientId: string
    ): Promise<ThanosAPIClient> {
        const endpoint = `/api/client`;
        const url = this.baseURL + endpoint;

        const config: any = {
            method: "post",
            url: url,
            data: {
                data: {
                    attributes: {
                        client_key: clientId,
                    },
                    type: "client",
                },
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value,
            },
        };

        const response: Promise<ThanosAPIClient> = await axios(config)
            .then((r: any) => {
                const clientData = r.data?.data;
                return clientData;
            })
            .catch((e: any) => {
                throw Error(e);
            });
        return response;
    }

    async clientsPatch(
        bearerToken: BearerToken,
        clientId: string,
        itemDeathRules: ThanosAPIItemDeathRulesObject,
        scenarioRules: ThanosAPIScenarioRule[],
        monthlyDecayLeadTime: number,
        monthlyDecaySalePrice: number,
        monthlyDecaySalesOrders: number,
        monthlyDecaySalesQty: number,
        simDaysPerTrial: number,
        simMaxTrials: number,
        simMinTrials: number,
        simNeighborhood: number,
        simTrialsThresholdHigh: number,
        simTrialsThresholdLow: number
    ): Promise<ThanosAPIClient> {
        const endpoint = `/api/client/${clientId}`;
        const url = this.baseURL + endpoint;

        const config: any = {
            method: "patch",
            url: url,
            data: {
                data: {
                    attributes: {
                        client_key: clientId,
                        item_death_rules: itemDeathRules,
                        scenario_rules: scenarioRules,
                        monthly_decay_lead_time: monthlyDecayLeadTime,
                        monthly_decay_sale_price: monthlyDecaySalePrice,
                        monthly_decay_sales_orders: monthlyDecaySalesOrders,
                        monthly_decay_sales_qty: monthlyDecaySalesQty,
                        sim_days_per_trial: simDaysPerTrial,
                        sim_max_trials: simMaxTrials,
                        sim_min_trials: simMinTrials,
                        sim_neighborhood: simNeighborhood,
                        sim_trials_threshold_high: simTrialsThresholdHigh,
                        sim_trials_threshold_low: simTrialsThresholdLow,
                    },
                    type: "client",
                },
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value,
            },
        };

        const response: Promise<ThanosAPIClient> = await axios(config)
            .then((r: any) => {
                const clientData = r.data?.data;
                return clientData;
            })
            .catch((e: any) => {
                throw Error(e);
            });
        return response;
    }

    async clientsGet(
        bearerToken: BearerToken,
        clientId?: string
    ): Promise<ThanosAPIClient[]> {
        const endpoint = clientId ? `/api/client/${clientId}` : `/api/client`;
        const url = this.baseURL + endpoint;

        const config: any = {
            method: "get",
            url: url,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value,
            },
        };

        const response: Promise<ThanosAPIClient[]> = await axios(config)
            .then((r: any) => {
                if (clientId) {
                    const clientData = r.data?.data;
                    return [clientData];
                } else {
                    const clientDataArray = r.data?.data;
                    return clientDataArray;
                }
            })
            .catch((e: any) => {
                throw Error(e);
            });
        return response;
    }

    async clientsDelete(
        bearerToken: BearerToken,
        clientId: string
    ): Promise<void> {
        const endpoint = `/api/client/${clientId}?force=1`;
        const url = this.baseURL + endpoint;

        const config: any = {
            method: "delete",
            url: url,
            data: {
                data: {
                    attributes: {
                        client_key: clientId,
                    },
                    type: "client",
                },
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value,
            },
        };

        await axios(config)
            .then((r: any) => {
                const clientData = r.data?.data;
                return clientData;
            })
            .catch((e: any) => {
                throw Error(e);
            });
    }

    /** Model Sets */
    async modelSetsPost(
        bearerToken: BearerToken,
        clientId: string,
        modelSetId: string,
        files?: ThanosAPIModelSetFiles
    ): Promise<ThanosAPIModelSet> {
        const endpoint = `/api/client/${clientId}/ms`;
        const url = this.baseURL + endpoint;

        const attributes = files
            ? {
                model_set_name: modelSetId,
                buckets: files.buckets,
                delay_days: files.delay_days,
                exceptions: files.exceptions,
                margin_floor: files.margin_floor,
                products: files.products,
                receiving: files.receiving,
                sales: files.sales,
            }
            : {
                model_set_name: modelSetId,
                buckets: {
                    file_type: "buckets",
                    file_name: "",
                    s3_path: "",
                },
                delay_days: {
                    file_type: "delay_days",
                    file_name: "",
                    s3_path: "",
                },
                exceptions: {
                    file_type: "exceptions",
                    file_name: "",
                    s3_path: "",
                },
                margin_floor: {
                    file_type: "margin_floor",
                    file_name: "",
                    s3_path: "",
                },
                products: {
                    file_type: "products",
                    file_name: "",
                    s3_path: "",
                },
                receiving: {
                    file_type: "receiving",
                    file_name: "",
                    s3_path: "",
                },
                sales: {
                    file_type: "sales",
                    file_name: "",
                    s3_path: "",
                },
            };

        const config: any = {
            method: "post",
            url: url,
            data: {
                data: {
                    attributes: attributes,
                    type: "modelSet",
                },
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value,
            },
        };

        const response: Promise<ThanosAPIModelSet> = await axios(config)
            .then((r: any) => {
                const clientData = r.data?.data;
                return clientData;
            })
            .catch((e: any) => {
                throw Error(e);
            });
        return response;
    }

    async modelSetsGet(
        bearerToken: BearerToken,
        clientId: string,
        modelSetId?: string
    ): Promise<ThanosAPIModelSet[]> {
        const endpoint = modelSetId
            ? `/api/client/${clientId}/ms/${modelSetId}`
            : `/api/client/${clientId}/ms`;
        const url = this.baseURL + endpoint;

        const config: any = {
            method: "get",
            url: url,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value,
            },
        };

        const response: Promise<ThanosAPIModelSet[]> = await axios(config)
            .then((r: any) => {
                if (modelSetId) {
                    const modelSet = r.data?.data;
                    return [modelSet];
                } else {
                    const modelSetArray = r.data?.data;
                    return modelSetArray;
                }
            })
            .catch((e: any) => {
                throw Error(e);
            });
        return response;
    }

    async modelSetsPatch(
        bearerToken: BearerToken,
        clientId: string,
        modelSetId: string
    ): Promise<void> {
        const endpoint = `/api/client/${clientId}/ms/${modelSetId}`;
        const url = this.baseURL + endpoint;

        const config: any = {
            method: "patch",
            url: url,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value,
            },
        };

        await axios(config)
            .then((r: any) => {
                const clientData = r.data?.data;
                return clientData;
            })
            .catch((e: any) => {
                throw Error(e);
            });
    }

    async modelSetsDelete(
        bearerToken: BearerToken,
        clientId: string,
        modelSetId: string
    ): Promise<void> {
        const endpoint = `/api/client/${clientId}/ms/${modelSetId}`;
        const url = this.baseURL + endpoint;

        const config: any = {
            method: "delete",
            url: url,
            data: {
                data: {
                    attributes: {
                        client_key: clientId,
                    },
                    type: "client",
                },
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value,
            },
        };

        await axios(config)
            .then((r: any) => {
                const clientData = r.data?.data;
                return clientData;
            })
            .catch((e: any) => {
                throw Error(e);
            });
    }

    /** Forecast Jobs */
    async forecastJobsPost(
        bearerToken: BearerToken,
        clientId: string,
        modelSetId: string,
        jobName: string,
        jobType?: string
    ): Promise<ThanosAPIForecastJob> {
        const endpoint = `/api/client/${clientId}/ms/${modelSetId}/forecast`;
        const url = this.baseURL + endpoint;

        const defaultJobType = "sku_txn";

        const attributes = {
            job_name: jobName,
            job_type: jobType ? jobType : defaultJobType,
        };

        const config: any = {
            method: "post",
            url: url,
            data: {
                data: {
                    attributes: attributes,
                    type: "forecastJob",
                },
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value,
            },
        };

        const response: Promise<ThanosAPIForecastJob> = await axios(config)
            .then((r: any) => {
                const forecastData = r.data?.data;
                return forecastData;
            })
            .catch((e: any) => {
                throw Error(e);
            });
        return response;
    }

    forecastJobsGet(
        bearerToken: BearerToken,
        clientId: string,
        modelSetId: string,
        forecastId?: string
    ): Promise<ThanosAPIForecastJob[]> {
        const endpoint = forecastId
            ? `/api/client/${clientId}/ms/${modelSetId}/forecast/${forecastId}`
            : `/api/client/${clientId}/ms/${modelSetId}/forecast`;
        const url = this.baseURL + endpoint;

        const config: any = {
            method: "get",
            url: url,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value,
            },
        };

        return axios(config)
            .then((r: any) => {
                if (forecastId) {
                    const forecastJob = r.data?.data;
                    return [forecastJob];
                } else {
                    const forecastJobArray = r.data?.data;
                    return forecastJobArray;
                }
            })
            .catch((e: any) => {
                throw Error(e);
            });
    }

    async forecastsJobsPatch(
        bearerToken: BearerToken,
        clientId: string,
        modelSetId: string,
        forecastJobName: string
    ): Promise<void> {
        const endpoint = `/api/client/${clientId}/ms/${modelSetId}/forecast/${forecastJobName}`;
        const url = this.baseURL + endpoint;

        const config: any = {
            method: "patch",
            url: url,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value,
            },
        };

        await axios(config)
            .then((r: any) => {
                const clientData = r.data?.data;
                return clientData;
            })
            .catch((e: any) => {
                throw Error(e);
            });
    }

    async forecastDownloadLinkPost(
        bearerToken: BearerToken,
        clientId: string,
        modelSetId: string,
        forecastJobName: string
    ): Promise<string> {
        const endpoint = `/api/client/${clientId}/ms/${modelSetId}/forecast/${forecastJobName}/downloadLink`;

        const url = this.baseURL + endpoint;

        const config: any = {
            method: "post",
            url: url,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value,
            },
        };

        const response: Promise<string> = await axios(config)
            .then((r: any) => {
                const downloadLink = r.data?.href;
                return downloadLink;
            })
            .catch((e: any) => {
                throw Error(e);
            });
        return response;
    }

    async forecastJobsDelete(
        bearerToken: BearerToken,
        clientId: string,
        modelSetId: string,
        forecastJobName: string
    ): Promise<void> {
        const endpoint = `/api/client/${clientId}/ms/${modelSetId}/forecast/${forecastJobName}`;
        const url = this.baseURL + endpoint;

        const config: any = {
            method: "delete",
            url: url,
            data: {
                data: {
                    attributes: {
                        client_key: clientId,
                    },
                    type: "client",
                },
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value,
            },
        };

        await axios(config)
            .then()
            .catch((e: any) => {
                throw Error(e);
            });
    }

    /** Optimization Jobs */
    async optimizationJobsPost(
        bearerToken: BearerToken,
        clientId: string,
        modelSetId: string,
        jobName: string,
        files?: ThanosAPIOptimizationJobFiles
    ): Promise<ThanosAPIOptimizationJob> {
        const endpoint = `/api/client/${clientId}/ms/${modelSetId}/job`;
        const url = this.baseURL + endpoint;

        const attributes = files
            ? {
                job_name: jobName,
                items: files.items,
                forecast: files.forecast,
            }
            : {
                job_name: jobName,
                items: {
                    file_type: "items",
                    file_name: "",
                    s3_path: "",
                },
                forecast: {
                    file_type: "forecast",
                    file_name: "",
                    s3_path: "",
                },
            };

        const config: any = {
            method: "post",
            url: url,
            data: {
                data: {
                    attributes: attributes,
                    type: "optimizationJob",
                },
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value,
            },
        };

        const response: Promise<ThanosAPIOptimizationJob> = await axios(config)
            .then((r: any) => {
                const forecastData = r.data?.data;
                return forecastData;
            })
            .catch((e: any) => {
                throw Error(e);
            });
        return response;
    }

    async optimizationJobsGet(
        bearerToken: BearerToken,
        clientId: string,
        modelSetId: string,
        optimizationJobId?: string
    ): Promise<ThanosAPIOptimizationJob[]> {
        const endpoint = optimizationJobId
            ? `/api/client/${clientId}/ms/${modelSetId}/job/${optimizationJobId}`
            : `/api/client/${clientId}/ms/${modelSetId}/job`;
        const url = this.baseURL + endpoint;

        const config: any = {
            method: "get",
            url: url,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value,
            },
        };

        const response: Promise<ThanosAPIOptimizationJob[]> = await axios(config)
            .then((r: any) => {
                if (optimizationJobId) {
                    const optimizationJob = r.data?.data;
                    return [optimizationJob];
                } else {
                    const optimizationJobArray = r.data?.data;
                    return optimizationJobArray;
                }
            })
            .catch((e: any) => {
                throw Error(e);
            });
        return response;
    }

    async optimizationJobsPatch(
        bearerToken: BearerToken,
        clientId: string,
        modelSetId: string,
        optimizationJobName: string
    ): Promise<void> {
        const endpoint = `/api/client/${clientId}/ms/${modelSetId}/job/${optimizationJobName}`;
        const url = this.baseURL + endpoint;

        const config: any = {
            method: "patch",
            url: url,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value,
            },
        };

        await axios(config)
            .then((r: any) => {
                const clientData = r.data?.data;
                return clientData;
            })
            .catch((e: any) => {
                throw Error(e);
            });
    }

    async optimizationDownloadLinkPost(
        bearerToken: BearerToken,
        clientId: string,
        modelSetId: string,
        optimizationJobName: string
    ): Promise<string> {
        const endpoint = `/api/client/${clientId}/ms/${modelSetId}/job/${optimizationJobName}/downloadLink`;

        const url = this.baseURL + endpoint;

        const config: any = {
            method: "post",
            url: url,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value,
            },
        };

        const response: Promise<string> = await axios(config)
            .then((r: any) => {
                const downloadLink = r.data?.href;
                return downloadLink;
            })
            .catch((e: any) => {
                throw Error(e);
            });
        return response;
    }

    async optimizationJobsDelete(
        bearerToken: BearerToken,
        clientId: string,
        modelSetId: string,
        optimizationJobName: string
    ): Promise<void> {
        const endpoint = `/api/client/${clientId}/ms/${modelSetId}/job/${optimizationJobName}`;
        const url = this.baseURL + endpoint;

        const config: any = {
            method: "delete",
            url: url,
            data: {
                data: {
                    attributes: {
                        client_key: clientId,
                    },
                    type: "client",
                },
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken.value,
            },
        };

        await axios(config)
            .then()
            .catch((e: any) => {
                throw Error(e);
            });
    }

    async filesPost(bearerToken: BearerToken, file: any): Promise<any> {
        const endpoint = "/files";

        const url = this.baseURL + endpoint;

        const form = new FormData();
        form.append("file", file);
        const response = await axios
            .post(url, form, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    Authorization: "Bearer " + bearerToken.value,
                },
            })
            .then((r: any) => {
                return r.data; // fileId
            })
            .catch((e: any) => {
                return e;
            });
        return response;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async filesGet(bearerToken: BearerToken, fileUUID: UUID): Promise<void> {
        console.error('Not implemented');
    }

    async downloadLinkGet(downloadLink: string): Promise<void> {
        const config: any = {
            method: "get",
            url: downloadLink,
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
        };

        const response = await axios(config).then(() => {
            window.open(downloadLink, "_blank");
        });
        return response;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async filesMetadataGet(bearerToken: BearerToken, fileUUID: UUID): Promise<void> {
        console.error('Not implemented');
    }
}

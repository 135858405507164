export enum InfraErrorMessages {
    Unknown = "An unknown error has occurred",
    InvalidUsernamePassword = "Invalid username/password",
    InvalidAuthToken = "Invalid auth token",
    NetworkError = "A network error has occurred",
    FileNotFound = "File not found",
    NetworkResponseWasNotOk = "Network response was not ok",
    MissingResponseBody = "Missing response body",
    MissingContentLengthHeader = "Missing content-length header",
    NotImplemented = "This feature has not yet been implemented",

}

export enum RepositoryErrorType {
    Unknown = "Unknown",
    NetworkError = "Network error",
    InvalidUsernameOrPassword = "Invalid username or password",
    InvalidAuthToken = "Invalid auth token",
    InvalidForecastInputFile = "Invalid forecast input file",
    UnableToStartForecast = "Unable to start forecast",
    FileNotFound = "File not found",
    NotImplemented = "Feature not implemented",
}

export class RepositoryError extends Error {
    method: string;
    errorType: RepositoryErrorType;

    constructor(method: string, type: RepositoryErrorType, message: string) {
        super(message);
        this.method = method;
        this.errorType = type;
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    }
}

export enum APIErrorType {
    Unknown = "Unknown",
    NetworkError = "Network error",
    InvalidCredentials = "Invalid credentials",
    InvalidAuthToken = "Invalid auth token",
    InvalidInput = "Invalid input",
    UnableToStartForecast = "Unable to start forecast",
    FileNotFound = "File not found",
    MalformedResponse = "Malformed response"
}

export class APIError extends Error {
    method: string;
    errorType: APIErrorType;

    constructor(method: string, type: APIErrorType, message: string) {
        super(message);
        this.method = method;
        this.errorType = type;
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    }
}
import {ThanosAPI} from "@/infra/api/thanos_api/thanos_api";
import {BearerToken} from "@/domain/bearerTokens/bearerToken";
import {HeimdallAPI} from "@/infra/api/heimdall/heimdall";
import {APIErrorType, InfraErrorMessages, RepositoryError, RepositoryErrorType} from "@/infra/common/errors";
import {UUID} from "@/domain/common/types";
import {
    AbstractFileDownloadRepository,
    AbstractFileUploadRepository
} from "@/infra/files/filesRepository/abstractFilesRepository";

import {FileMetadata} from "@/domain/file_metadata/file_metadata";

export class APIS3FilesRepository implements AbstractFileUploadRepository, AbstractFileDownloadRepository {
    private readonly api: ThanosAPI | HeimdallAPI;

    constructor(api: ThanosAPI | HeimdallAPI) {
        this.api = api;
    }

    async uploadFile(bearerToken: BearerToken, file: File): Promise<string> {
        return this.api.filesPost(bearerToken, file)
            .catch((e) => {
                if (e.errorType == APIErrorType.InvalidAuthToken) {
                    throw new RepositoryError("uploadFile", RepositoryErrorType.InvalidAuthToken, InfraErrorMessages.InvalidAuthToken);
                } else {
                    throw new RepositoryError("uploadFile", RepositoryErrorType.Unknown, e);
                }
            });
    }

    /**
     * This method is used to download a file.
     *
     * @param bearerToken - The bearer token to use for authentication.
     * @param fileUUID - The UUID of the file to download.
     * @param filename - The name of the file to download.
     * @param progressCallback - An optional callback function that will be called throughout the download
     * to update the upstream on progress.
     */
    async downloadFile(
        bearerToken: BearerToken,
        fileUUID: UUID,
        filename?: string,
        progressCallback?: (progress: number) => void
    ):
        Promise<void> {
        return this.api.filesGet(bearerToken, fileUUID, filename, progressCallback)
            .catch((e) => {
                if (e.errorType == APIErrorType.InvalidAuthToken) {
                    throw new RepositoryError("downloadFile", RepositoryErrorType.InvalidAuthToken,
                        InfraErrorMessages.InvalidAuthToken);
                } else if (e.errorType == APIErrorType.FileNotFound) {
                    throw new RepositoryError("downloadFile", RepositoryErrorType.FileNotFound,
                        InfraErrorMessages.FileNotFound);
                } else {
                    throw new RepositoryError("downloadFile", RepositoryErrorType.Unknown, e);
                }
            });
    }

    async getFileMetadata(bearerToken: BearerToken, fileUUID: UUID): Promise<FileMetadata> {
        return this.api.filesMetadataGet(bearerToken, fileUUID)
            .then((response) => {
                return new FileMetadata({
                    fileUUID: response.file_uuid,
                    fileName: response.file_name,
                    uploadedDatetime: new Date(response.upload_datetime),
                })
            })
            .catch((e) => {
                if (e.errorType == APIErrorType.InvalidAuthToken) {
                    throw new RepositoryError("getFileMetadata", RepositoryErrorType.InvalidAuthToken,
                        InfraErrorMessages.InvalidAuthToken);
                } else if (e.errorType == APIErrorType.FileNotFound) {
                    throw new RepositoryError("getFileMetadata", RepositoryErrorType.FileNotFound,
                        InfraErrorMessages.FileNotFound);
                } else {
                    throw new RepositoryError("getFileMetadata", RepositoryErrorType.Unknown, e);
                }
            });

    }
}

import { HeimdallAPI } from "@/infra/api/heimdall/heimdall";
import { BearerToken } from "@/domain/bearerTokens/bearerToken";
import { AbstractBearerTokensRepository } from "@/infra/bearerTokens/abstractBearerTokensRepository";
import { APIErrorType, InfraErrorMessages, RepositoryError, RepositoryErrorType } from "@/infra/common/errors";

export class APIBearerTokensRepository implements AbstractBearerTokensRepository {
  private readonly api: HeimdallAPI;

  constructor(api: HeimdallAPI) {
    this.api = api;
  }

  async obtainBearerToken(
    username: string,
    password: string
  ): Promise<BearerToken> {
    return this.api.tokensPost(username, password)
      .then((bearerToken) => new BearerToken(bearerToken))
      .catch(e => {
        if (e.errorType == APIErrorType.InvalidCredentials) {
          throw new RepositoryError(
            "obtainBearerToken",
            RepositoryErrorType.InvalidUsernameOrPassword,
            InfraErrorMessages.InvalidUsernamePassword
          );
        } else if (e.errorType == APIErrorType.NetworkError) {
          throw new RepositoryError(
            "obtainBearerToken",
            RepositoryErrorType.NetworkError,
            InfraErrorMessages.NetworkError
          );
        } else {
          throw new RepositoryError(
            "obtainBearerToken",
            RepositoryErrorType.Unknown,
            InfraErrorMessages.Unknown
          );
        }
      });
  }

  async isBearerTokenValid(token: BearerToken): Promise<boolean> {
    return this.api.tokensValidatePost(token)
      .then((isValidToken) => isValidToken)
      .catch(() => {
        throw new RepositoryError(
          "obtainBearerToken",
          RepositoryErrorType.Unknown,
          InfraErrorMessages.Unknown.valueOf()
        );
      });
  }
}

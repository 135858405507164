import {Customer} from "@/domain/customers/customer";
import {JobMetadata} from "@/domain/legacyOptimizer/jobMetadata/jobMetadata";


interface JobsSummaryParams {
    customer: Customer;
    activeJobsMetadata: JobMetadata[];
    successfulJobsMetadata: JobMetadata[];
    failedJobsMetadata: JobMetadata[];
}

/**
 * Summary of active/completed jobs for a customer.
 */
export class JobsSummary {
    customer: Customer;
    // Jobs currently running (includes stuck jobs)
    activeJobsMetadata: JobMetadata[];
    // Jobs that have completed successfully
    successfulJobsMetadata: JobMetadata[];
    // Jobs that have failed
    failedJobsMetadata: JobMetadata[];

    constructor(params: JobsSummaryParams) {
        this.customer = params.customer;
        this.activeJobsMetadata = params.activeJobsMetadata;
        this.successfulJobsMetadata = params.successfulJobsMetadata;
        this.failedJobsMetadata = params.failedJobsMetadata;
    }
}
